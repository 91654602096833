import Taxi from '../util/billing_items/taxi'

up.compiler('[billing-item-taxi]', (element) => {
  const form = element.closest('form')

  const taxiBillingItem = new Taxi(element)

  form.addEventListener('submit', function(event) {
    if (!taxiBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

export default class Address {
  constructor(streetInput, zipInput, cityInput) {
    this.streetInput = streetInput
    this.zipInput = zipInput
    this.cityInput = cityInput
  }

  toString(defaultString = '(unbekannt)') {
    const zipAndCity = `${this.zipInput.value} ${this.cityInput.value}`.replace(/\s+/, ' ').trim()
    const street = this.streetInput.value.trim()
    return [street, zipAndCity].filter(up.util.isPresent).join(', ') || defaultString
  }
}

import BillingItem from './billing_item'

export default class BillingItemWithReceipts extends BillingItem {
  constructor(element) {
    super(element)
    this.hiddenFileInput = element.querySelector('.bill_billing_items_receipts input[type="file"]')
    this.fileInput = element.querySelector('.bill_billing_items_receipts [file-input--filename]')

    this.userInputFields.push(this.fileInput)
  }

  isValid() {
    let valid = super.isValid()
    if (!this.isDestroyed() && this.hiddenFileInput.value === '') {
      this.markAsInvalid(this.fileInput)
      valid = false
    }
    return valid
  }
}

import BillingItem from '../billing_item'
import DailyAllowancesPart from './daily_allowances_part'

export default class DailyAllowances extends BillingItem {
  constructor(element) {
    super(element)
    this.partsContainer = element.querySelector('.billing-item-daily-allowances-parts')
    this.partHtml = this.partsContainer.dataset.partHtml
    this.allowancePerDay = parseFloat(this.partsContainer.dataset.allowancePerDay)
    this.parts = []

    this.createPartsFromDateInputs()
    element.querySelector('button[add-new-day]').addEventListener('click', (event) => {
      this.createNewPart()
    })
  }

  createPartsFromDateInputs() {
    const form = this.element.closest('form')
    const startDay = form.querySelector(`[name="${this.partsContainer.dataset.startDateName}"]`)?.value
    const startDate = startDay ? new Date(startDay) : null
    const endDay = form.querySelector(`[name="${this.partsContainer.dataset.endDateName}"]`)?.value
    const endDate = endDay ? new Date(endDay) : null

    if (startDate && endDate && startDate.getTime() < endDate.getTime()) {
      this.createNewPart({ date: startDate, type: 'arrival' })
      const date = new Date(startDate)
      date.setDate(date.getDate() + 1)
      while (date.getTime() < endDate.getTime()) {
        this.createNewPart({ date: new Date(date), type: 'full_day' })
        date.setDate(date.getDate() + 1)
      }
      this.createNewPart({ date: endDate, type: 'departure' })
    } else if (startDate && endDate && startDate.getTime() === endDate.getTime()) {
      this.createNewPart({ date: startDate, type: 'single_day' })
    } else {
      this.createNewPart()
    }
  }

  createNewPart({ date = null, type = null } = {}) {
    const content = this.partHtml.replaceAll('__part_index__', this.partsContainer.children.length)

    const partElement = up.element.createFromHTML(content)

    if (date) partElement.querySelector('[name*="date"]').valueAsDate = date
    if (type) partElement.querySelector('[name*="type"]').value = type

    this.partsContainer.append(partElement)
    up.hello(partElement)

    const part = new DailyAllowancesPart(partElement, this)
    this.parts.push(part)
    this.refreshAll()
  }

  refreshAll() {
    this.generateDescription()
    this.generateTotalAmount()
  }

  generateDescription() {
    const description = this.parts
      .filter(part => !part.isDestroyed())
      .sort((a, b) => {
        const dateA = new Date(a.fields.date.value)
        const dateB = new Date(b.fields.date.value)
        if (isNaN(dateA)) return 1
        if (isNaN(dateB)) return -1
        return dateA - dateB
      })
      .map(part => part.generateDescription()).join('\n')
    this.updateDescription(description)
  }

  generateTotalAmount() {
    this.fields.amount.value = this.calculateAmount().toFixed(2)
    up.emit('app:total-billing-items-amount:update')
  }

  isValid() {
    let valid = super.isValid()

    if (this.isDestroyed()) {
      return valid
    }

    this.parts.forEach(part => {
      // ensure each part.isValid() function runs regardless of the value of valid
      const partValid = part.isValid()
      valid &&= partValid
    })

    return valid
  }

  calculateAmount() {
    const amounts = this.parts.map(part => part.getAmount())
    return amounts.reduce((a, b) => a + b, 0)
  }
}

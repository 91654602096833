import getDeviceType from '../util/device_type'
import QrScanner from 'qr-scanner'
import environment from '../../shared/util/environment'

up.compiler('body', async (element) => {
  let debounceTimeout
  const debounceDelay = 100

  function update() {
    element.dataset.deviceType = getDeviceType()
  }

  function debouncedUpdate() {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(update, debounceDelay)
  }

  const resizeObserver = new ResizeObserver(debouncedUpdate)
  resizeObserver.observe(element)

  const hasCamera = await QrScanner.hasCamera()
  element.classList.toggle('-has-camera', hasCamera || environment.isFeatureSpec)

  up.destructor(element, () => {
    resizeObserver.disconnect()
  })

})

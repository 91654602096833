up.compiler('[login-code-field]', (element, { invalidPattern }) => {

  const invalidPatternRegExp = new RegExp(invalidPattern, 'g')

  class Formatter {
    constructor(inputValue) {
      const upperCased = inputValue.toUpperCase()
      this.formattedValue = upperCased.replace(invalidPatternRegExp, '')
    }
  }

  function onInput() {
    const formatter = new Formatter(element.value)
    element.value = formatter.formattedValue
  }

  element.addEventListener('input', onInput)

})

import environment from '../../shared/util/environment'
import QrScanner from 'qr-scanner'

up.compiler('.qr-scanner', async (element, { permissionDeniedHtml, noCameraHtml, invalidDataHtml }) => {
  const hasCamera = await QrScanner.hasCamera()
  const ignoreMissingCamera = environment.isFeatureSpec // CI runners have no camera

  if (!hasCamera && !ignoreMissingCamera) {
    element.classList.add('-no-camera')
    replaceContent(noCameraHtml)
    return
  }

  const videoElement = up.element.affix(element, 'video.qr-scanner--video')
  const qrScanner = new QrScanner(videoElement, onResultFound, {
    highlightScanRegion: true,
    returnDetailedScanResult: true,
  })

  up.on(element, 'click', '.qr-scanner--button', displayQrScanner)
  up.on(element, 'app:qr-scanner:test-scan', testScan)

  checkForExistingPermission()

  function checkForExistingPermission() {
    // Checks for an existing permission without actively requesting it.
    // We do this to avoid opening the browser's permission dialog immediately when the element is rendered.
    // Note that Firefox at time of writing does not support the "camera" permission and raises an error.
    // Hence, Firefox users need to click the button explicitly, even if they granted permission in a previous session.
    navigator.permissions.query({ name: 'camera' })
      .then(onPermission)
      .catch(console.warn)
  }

  function displayQrScanner() {
    element.classList.add('-busy')

    // QrScanner#start will automatically call `navigator.mediaDevices.getUserMedia({ video: true })`
    // and display the permission dialog, if necessary.
    qrScanner.start()
      .then(onStart)
      .catch(onPermissionDenied)
      .finally(() => { element.classList.remove('-busy') })
  }

  function onStart() {
    element.classList.add('-active')
  }

  function onPermissionDenied() {
    element.classList.add('-permission-denied')
    replaceContent(permissionDeniedHtml)
  }

  function onPermission({ state }) {
    if (state === 'granted') {
      displayQrScanner()
    } else if (state === 'denied') {
      onPermissionDenied()
    }
  }

  function replaceContent(html) {
    element.innerHTML = html
  }

  async function testScan({ uri }) {
    const result = await QrScanner.scanImage(uri, { alsoTryWithoutScanRegion: true })
    onResultFound(result)
  }

  let handlingResult = false
  function onResultFound(result) {
    if (handlingResult) return
    handlingResult = true

    const url = result.data

    if (isUrlInApplication(url)) {
      // delay is sometimes intended for tests
      const navigationDelay = window.delayQrScannerNavigation || 0
      up.util.timer(navigationDelay, () => {
        location.href = url
      })
    } else {
      console.warn('Unsupported URL', url)
      up.layer.open({
        content: invalidDataHtml,
        size: 'auto',
        onDismissed: () => { handlingResult = false },
      })
    }
  }

  function isUrlInApplication(string) {
    try {
      const url = new URL(string)
      return shortHostOf(url) === shortHostOf(location)
    } catch {}
  }

  function shortHostOf(url) {
    return url.hostname.replace(/^www\./, '')
  }

  up.destructor(element, () => {
    qrScanner?.destroy()
  })
})

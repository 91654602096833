up.compiler('input[data-copy-value-from]', (input) => {
  const originSelector = input.dataset.copyValueFrom
  const origin = document.querySelector(originSelector)

  function synchronize() {
    input.value = origin.value
    up.emit(input, 'change', { copiedFrom: origin })
  }

  const unbindOriginListener = up.on(origin, 'change', synchronize)
  const unbindInputListener = up.on(input, 'input change', (event) => {
    if (event.copiedFrom !== origin) {
      // Something was entered into this field, so turn off synchronisation
      unbindOriginListener()
      unbindInputListener()
    }
  })

  synchronize()

  return [unbindOriginListener, unbindInputListener]
})

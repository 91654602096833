import detectMobile from 'ismobilejs'
import flatpickr from 'flatpickr/dist/esm'
import { German } from 'flatpickr/dist/l10n/de.js'
import 'flatpickr/dist/themes/light.css'

up.compiler('[date-picker]', (element, { previousMonthIcon, nextMonthIcon }) => {

  // keep native date picker on mobile devices
  if (detectMobile().any) return

  const labels = Array.from(element.labels) // fetch labels now, because flatpickr turns it into type "hidden" (which can't have labels)

  const picker = flatpickr(element, {
    locale: German,
    allowInput: true,
    altInput: true,
    altFormat: 'd.m.Y',
    dateFormat: 'Y-m-d',
    ariaDateFormat: 'd.F Y',
    prevArrow: previousMonthIcon,
    nextArrow: nextMonthIcon,
  })

  const altInput = picker.altInput
  altInput.placeholder ||= '__.__.____'

  // Helper attribute for tests
  altInput.setAttribute('date-picker--user-input', true)

  // Update label(s) to point to user input, instead of the (now hidden) `element`
  if (!altInput.id) {
    altInput.setAttribute('id', `date-picker-${Math.random().toString(36).replace(/^0\./, '')}`)
  }
  labels.forEach((label) => {
    label.setAttribute('for', altInput.id)
  })

  // When users type into the input, we want to immediately reflect their input in the picker.
  // For that, we need to trigger a `blur` event. Because that would also update the user input,
  // we only do it when a well-formatted value is given.
  altInput.addEventListener('input', function() {
    if (!altInput.value || altInput.value.match(/^\d\d?.\d\d?.\d{4}$/)) {
      up.emit(altInput, 'blur')
    }
  })

  // When an invalid value was entered, the field ist blurred, we want to clear the user input.
  // Otherwise flatpickr would try to parse it and maybe pick a date that the user did not actually enter.
  // Discarding any input in that case felt like the saner approach.
  altInput.addEventListener('change', function() {
    if (altInput.value && !altInput.value.match(/^\d\d?.\d\d?.\d{4}$/)) {
      altInput.value = ''
    }
  })

  element.datePickerInput = altInput

  return () => {
    picker.destroy()
  }

})

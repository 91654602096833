import Hotel from '../util/billing_items/hotel'

up.compiler('[billing-item-hotel]', (element) => {
  const form = element.closest('form')

  const hotelBillingItem = new Hotel(element)

  form.addEventListener('submit', function(event) {
    if (!hotelBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

import environment from '../util/environment'

up.compiler('form[confirm-unsaved-changes]', (element) => {
  let confirmed = false
  let unsavedChanges = !!element.querySelector('.is-invalid')
  let poppedState

  function requestConfirmation({ onConfirm, onCancel }) {
    up.layer.open({
      mode: 'modal',
      size: 'small',
      content: up.element.createFromHTML(`
        <div>
          <h4>Ungespeicherte Änderungen</h4>
          
          <p>Deine Eingaben wurden noch nicht gespeichert.</p>
          <p>Möchtest du fortfahren und alle Änderungen verwerfen?</p>
          
          <div class="action-bar">
            <button up-accept type="button" class="btn btn-danger">Fortfahren</button>
            <button up-dismiss type="button" class="btn btn-light">Zurück</button>
          </div>
        </div>
      `),
      onAccepted: () => {
        markConfirmed()
        onConfirm()
      },
      onDismissed: onCancel,
    })
  }

  function onChange() {
    unsavedChanges = true
    confirmed = false
  }

  function onSubmit() {
    unsavedChanges = false
  }

  function needsConfirmation() {
    return unsavedChanges && !confirmed
  }

  function markConfirmed() {
    confirmed = true
  }

  function isUnsavedFormInsideCurrentLayer() {
    return up.layer.current.contains(element)
  }

  function onLinkFollow(event) {
    const { target, renderOptions } = event

    const renderTarget = up.fragment.closest(target, renderOptions.target)
    const renderLayer = up.layer.get(renderOptions.layer)

    if (renderTarget && !renderTarget.contains(element)) {
      // replaces other fragment
    } else if (renderLayer !== up.layer.get(element)) {
      // targets other layer
    } else if (needsConfirmation()) {
      event.preventDefault()
      requestConfirmation({ onConfirm: _ => up.follow(target) })
    }
  }

  function onDismissLayer(event) {
    if (needsConfirmation() && isUnsavedFormInsideCurrentLayer()) {
      event.preventDefault()
      requestConfirmation({ onConfirm: _ => up.layer.dismiss(event.value) })
    }
  }

  function onBack(event) {
    if (needsConfirmation()) {
      event.preventDefault()

      requestConfirmation({
        onConfirm: () => {
          // Preventing the up:location:restore event does not stop the current location from updating, it only stop the rendering
          // so to go back we just need to render the page again, or close the overlay
          if (isUnsavedFormInsideCurrentLayer() && !up.layer.current.isRoot()) {
            up.layer.dismiss()
          } else {
            up.navigate({ url: window.location.href, history: false })
          }
        },
        onCancel: () => {
          // When dismissing the confirmation, restore the previous state
          window.history.pushState(poppedState, null, up.history.previousLocation)
        },
      })
    }
  }

  function onUnload(event) {
    if (environment.isDevelopment) {
      // In development, we regularly auto-reload and want to avoid annoying confirmation dialogs.
    } else if (needsConfirmation()) {
      // The "beforeunload" event does not allow displaying a custom dialog; this shows a browser-specific dialog.
      event.preventDefault()
      event.returnValue = true
    }
  }

  function onPopstate({ state }) {
    poppedState = state
  }

  return [
    up.on(element, 'input', onChange),
    up.on(element, 'up:form:submit', onSubmit),
    up.on('up:link:follow', onLinkFollow),
    up.on('up:layer:dismiss', onDismissLayer),
    up.on('up:location:restore', onBack),
    up.on(window, 'beforeunload', onUnload),
    up.on(window, 'popstate', onPopstate),
  ]
})

import Bus from '../util/billing_items/bus'

up.compiler('[billing-item-bus]', (element) => {
  const form = element.closest('form')

  const busBillingItem = new Bus(element)

  form.addEventListener('submit', function(event) {
    if (!busBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

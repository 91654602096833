import environment from '../util/environment'

export default async function fadeOut(element) {
  if (environment.isTest) return Promise.resolve()

  const { opacity, height, paddingBlock, marginBlock } = getComputedStyle(element)
  const keyframes = [
    { opacity, height, paddingBlock, marginBlock },
    { opacity: 0, height, paddingBlock, marginBlock },
    { opacity: 0, height: 0, paddingBlock: 0, marginBlock: 0 },
  ]
  await element.animate(keyframes, { duration: 400, easing: 'ease-out' }).finished
}

import getDeviceType from '../util/device_type'

up.compiler('[autofocus-only-desktop]', (element) => {

  if (getDeviceType() === 'desktop') {
    up.util.task(() => {
      element.focus()
    })
  }

})

import Train from '../util/billing_items/train'

up.compiler('[billing-item-train]', (element) => {
  const form = element.closest('form')

  const trainBillingItem = new Train(element)

  form.addEventListener('submit', function(event) {
    if (!trainBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

import BillingItemWithReceipts from '../billing_item_with_receipts'
import Address from '../address'

export default class EffectiveExpenses extends BillingItemWithReceipts {
  constructor(element) {
    super(element)
    this.fields.street = this.findField('street_and_number')
    this.fields.zip = this.findField('zip')
    this.fields.city = this.findField('city')
    this.address = new Address(this.fields.street, this.fields.zip, this.fields.city)

    this.userInputFields.push(this.fields.street, this.fields.zip, this.fields.city, this.fields.amount)
  }
}

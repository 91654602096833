import detectMobile from 'ismobilejs'
import Cleave from 'cleave.js'

up.compiler('[time-picker]', (element) => {

  // keep native picker on mobile devices
  if (detectMobile().any) return

  element.type = 'text'
  element.placeholder = '__:__'

  const cleave = new Cleave(element, {
    time: true,
    timePattern: ['h', 'm'],
    timeFormat: '24',
  })

  return () => { cleave.destroy() }

})

import validateBillingItemField from '../../shared/util/validate_billing_item_field'

up.compiler('[billing-details]', (element) => {
  const form = element.closest('form')
  const fields = element.querySelectorAll('input, textarea, select')

  form.addEventListener('submit', function(event) {
    if (!isValid()) {
      event.preventDefault()
    }
  })

  function isValid() {
    let valid = true

    fields.forEach((field) => {
      const fieldValid = validateBillingItemField(field)
      valid &&= fieldValid
    })

    const hasBillingItems = [...document.querySelectorAll('.billing-item')].some(el => !el.billingItem.isDestroyed())
    if (valid && !hasBillingItems) {
      alert('Mindestens eine Abrechnungsposition muss angegeben werden.')
      valid = false
    }

    return valid
  }
})

// This compiler can only run after all [billing-item-xxx] compilers have run
up.compiler('[total-billing-items-amount]', { priority: -1 }, (element) => {

  function updateAmount() {
    const billingItems = [...document.querySelectorAll('.billing-item')].map(el => el.billingItem)
    const amounts = billingItems.map(billingItem => billingItem.getAmount())
    const total = amounts.reduce((sum, amount) => sum + amount, 0)

    element.textContent = total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
  }

  updateAmount()
  return up.on('app:total-billing-items-amount:update', updateAmount)

})

import BillingItemWithReceipts from '../billing_item_with_receipts'

export default class Parking extends BillingItemWithReceipts {
  constructor(element) {
    super(element)

    this.fields.location = this.findField('location')
    this.userInputFields.push(this.fields.location, this.fields.amount)

    this.registerEventListeners()
  }

  generateDescription() {
    const location = this.fields.location.value || '(unbekannt)'
    this.updateDescription(`Parkplatz in ${location}.`)
  }
}

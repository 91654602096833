import Car from './car'

const MAX_AMOUNT = 130

export default class Car20 extends Car {
  calculateAmount() {
    const value = 0.2 * this.fields.distance.value
    return Math.min(value, MAX_AMOUNT)
  }
}

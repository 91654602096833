import isMobile from 'ismobilejs'

export default function getDeviceType() {
  const { phone, tablet } = isMobile()

  if (phone) {
    return 'phone'
  } else if (tablet || navigator.userAgent.match(/\bSamsungBrowser/)) {
    return 'tablet'
  } else {
    return 'desktop'
  }
}

import EffectiveExpenses from './effective_expenses'

export default class Hotel extends EffectiveExpenses {
  constructor(element) {
    super(element)
    this.fields.name = this.findField('name')

    this.userInputFields.push(this.fields.name)
    this.registerEventListeners()
  }

  generateDescription() {
    this.updateDescription(`Hotel „${this.fields.name.value}“ in ${this.address.toString()}.`)
  }
}

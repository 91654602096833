import fadeOut from '../util/fade_out'

up.compiler('.billing-item', (element) => {

  const amountField = element.querySelector('[name*="[amount]"]')
  const destroyField = element.querySelector('[name*="_destroy"][type="checkbox"]')

  amountField?.addEventListener('input', onInputAmount)
  destroyField?.addEventListener('input', onInputDestroy)

  function onInputAmount() {
    updateTotal()
  }

  async function onInputDestroy({ target }) {
    target.checked &&= confirm(`${target.labels[0]?.title || 'Löschen'}?`)
    if (target.checked) await fadeOut(element)
    element.hidden = target.checked
    updateTotal()
  }

  function updateTotal() {
    up.emit('app:total-billing-items-amount:update')
  }

})

import Car30 from '../util/billing_items/car30'

up.compiler('[billing-item-car-30]', (element) => {
  const form = element.closest('form')
  const distanceInput = element.querySelector('[name*="distance"]')

  const carBillingItem = new Car30(element)

  distanceInput.addEventListener('focusout', function() {
    carBillingItem.setAmount()
  })

  form.addEventListener('submit', function(event) {
    if (!carBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

import Parking from '../util/billing_items/parking'

up.compiler('[billing-item-parking]', (element) => {
  const form = element.closest('form')

  const parkingBillingItem = new Parking(element)

  form.addEventListener('submit', function(event) {
    if (!parkingBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

import Plane from '../util/billing_items/plane'

up.compiler('[billing-item-plane]', (element) => {
  const form = element.closest('form')

  const planeBillingItem = new Plane(element)

  form.addEventListener('submit', function(event) {
    if (!planeBillingItem.isValid()) {
      event.preventDefault()
    }
  })
})

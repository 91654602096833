import autosize from 'autosize'
import validateBillingItemField from '../../shared/util/validate_billing_item_field'

export default class BillingItem {
  constructor(element) {
    this.element = element
    this.fields = {}
    this.fields.amount = this.findField('amount')
    this.fields.description = this.findField('description')
    this.fields.reason = this.findField('reason')
    this.fields.destroy = element.querySelector('[name*="_destroy"][type="checkbox"]')

    this.userInputFields = []
    if (this.fields.reason) {
      this.userInputFields.push(this.fields.reason)
    }

    this.element.billingItem = this
  }

  findField(fieldName) {
    return this.element.querySelector(`[name*="${fieldName}"]`)
  }

  updateDescription(text) {
    const field = this.fields.description
    field.value = text
    window.as = autosize
    autosize.update(field)
  }

  getUserInputFields() {
    return this.userInputFields
  }

  generateDescription() {
    // override in subclasses
  }

  registerEventListeners() {
    this.getUserInputFields().forEach((input) => {
      input?.addEventListener('change', function() {
        this.generateDescription()
      }.bind(this))
    })
  }

  isValid() {
    if (this.isDestroyed()) {
      this.userInputFields.forEach((input) => {
        this.markAsValid(input)
      })
      return true
    }

    let valid = true

    this.userInputFields.forEach((field) => {
      if (!field) return // tests my have null values here

      const fieldValid = validateBillingItemField(field)
      valid &&= fieldValid
    })

    if (this.getAmount() <= 0) {
      this.markAsInvalid(this.fields.amount)
      valid = false
    }
    return valid
  }

  getAmount() {
    if (this.isDestroyed()) {
      return 0
    } else {
      return parseFloat(this.fields.amount.value) || 0
    }
  }

  isDestroyed() {
    return this.fields.destroy && this.fields.destroy.checked
  }

  markAsInvalid(input) {
    input.classList.add('is-invalid')
  }

  markAsValid(input) {
    input.classList.remove('is-invalid')
  }
}

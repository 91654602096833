import IBAN from 'iban'
import compileInvalidFormField from '../../shared/util/invalid_form_field'

export default function validateBillingItemField(field) {
  if (field.tagName === 'LABEL') return true
  if (field.dataset.skipValidation) return true

  let fieldValid = field.value.trim() !== ''
  const wasValid = !field.matches('.is-invalid')

  if (field.type === 'number') {
    fieldValid &&= parseFloat(field.value) > 0
  }
  if (field.name.includes('[iban]')) {
    fieldValid &&= IBAN.isValid(field.value)
  }

  field.classList.toggle('is-invalid', !fieldValid)
  if (wasValid && !fieldValid) compileInvalidFormField(field)

  return fieldValid
}
